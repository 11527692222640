@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-neutral-100 font-poppins h-[100%];
  }

  body {
    @apply h-[100%] flex flex-col;
  }

  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [type='color'],
  [multiple],
  textarea,
  select {
    @apply w-full rounded-lg py-3 px-4 text-fs-9 font-medium text-neutral-100 bg-white border-[#D0D5DD] ring-0 transition duration-200 focus:bg-white focus:border-gray-300 focus:ring-0 placeholder:text-neutral-60 disabled:bg-gray-50 disabled:text-gray-400;
  }

  [type='checkbox'] {
    @apply transition focus:ring-0 focus:ring-offset-0 w-5 h-5 rounded bg-white border-neutral-30 cursor-pointer text-danger;
  }

  [type='radio'] {
    @apply transition focus:ring-0 focus:ring-offset-0 bg-neutral-30 w-6 h-6 border-neutral-30;
  }

  /* THIS IS FOR CUSTOM CALENDAR */
  .custom-calendar {
    box-shadow: 0px 8px 8px -4px #10182808;
    box-shadow: 0px 20px 24px -4px #10182814;
    @apply rounded-lg;
  }

  .custom-calendar .rmdp-wrapper {
    border: 1px solid #000000;
    box-shadow: 0 0 5px #000000;
  }

  .custom-calendar .rmdp-panel-body li {
    background-color: #000000;
    box-shadow: 0 0 2px #000000;
  }

  .custom-calendar .rmdp-week-day {
    @apply font-semibold text-neutral-100;
  }

  .custom-calendar .rmdp-day.rmdp-deactive {
    @apply hover:bg-neutral-20 text-neutral-30;
  }

  .custom-calendar .rmdp-ym .rmdp-day {
    @apply text-neutral-70;
  }

  .custom-calendar .rmdp-ym .rmdp-day span {
    @apply rounded-none hover:rounded-none !important;
  }

  .custom-calendar .rmdp-range {
    @apply bg-danger;
  }

  .custom-calendar .rmdp-calendar {
    @apply py-5 px-6;
  }

  .custom-calendar .rmdp-arrow {
    border-width: 0 2px 2px 0;
    @apply border-neutral-50;
  }

  .custom-calendar.rmdp-ep-shadow {
    @apply !hidden;
  }

  .custom-calendar .rmdp-arrow-container:hover {
    @apply text-neutral-50;
  }

  .custom-calendar .rmdp-arrow-container:hover .rmdp-arrow {
    @apply border-primary-50;
  }

  .custom-calendar .rmdp-arrow-container:hover {
    @apply bg-transparent shadow-none text-neutral-50;
  }

  .custom-calendar .rmdp-panel-body::-webkit-scrollbar-thumb {
    background: #000000 !important;
  }

  .custom-calendar .rmdp-day.rmdp-today:not(.rmdp-range) span {
    @apply !bg-white !text-danger font-semibold;
  }

  .custom-calendar .rmdp-rtl .rmdp-panel {
    border-left: unset;
    border-right: 1px solid #000000;
  }

  .custom-calendar .rmdp-header {
    @apply mb-3 px-5;
  }

  .custom-calendar .rmdp-header-values {
    @apply text-gray-700 text-base font-medium;
  }

  .custom-calendar .rmdp-header-values span {
    @apply text-gray-700 text-[16px] font-medium;
  }

  /* month picker */
  .custom-calendar .rmdp-month-picker .rmdp-day,
  .custom-calendar .rmdp-year-picker .rmdp-day {
    @apply w-[56px] h-[56px] rounded-lg overflow-hidden;
  }

  .custom-calendar .rmdp-month-picker .rmdp-day span,
  .custom-calendar .rmdp-year-picker .rmdp-day span {
    @apply w-[56px] h-[56px] !rounded-md left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
  }

  .custom-calendar .rmdp-month-picker .rmdp-day:not(.rmdp-day-hidden) span:hover:hover,
  .custom-calendar .rmdp-year-picker .rmdp-day:not(.rmdp-day-hidden) span:hover:hover {
    @apply shadow-none  !rounded-md text-danger bg-danger-100;
  }

  .custom-calendar .rmdp-month-picker .rmdp-day.rmdp-today span,
  .custom-calendar .rmdp-year-picker .rmdp-day.rmdp-today span {
    @apply bg-white text-danger-200 font-semibold;
  }

  /* day picker */
  .custom-calendar .rmdp-day.rmdp-selected span:not(.highlight) {
    @apply text-white bg-danger-100;
  }

  .custom-calendar .rmdp-day:not(.rmdp-day-hidden) span:hover {
    @apply hover:bg-neutral-20 hover:text-black;
  }

  .custom-calendar .rmdp-day {
    @apply text-neutral-70;
  }

  .custom-calendar .rmdp-day.rmdp-disabled {
    @apply pointer-events-none;
  }

  .custom-calendar .rmdp-day.rmdp-disabled span {
    @apply text-neutral-30 bg-white;
  }

  .custom-calendar .rmdp-day.rmdp-selected span:not(.highlight) {
    @apply shadow-none bg-danger text-white;
  }

  .custom-calendar .rmdp-day:not(.rmdp-day-hidden) span:hover:hover {
    @apply bg-danger-100 text-danger;
  }

  .custom-calendar .rmdp-day.rmdp-deactive:hover {
    @apply bg-transparent;
  }

  /* range picker */
  .custom-calendar .rmdp-day.rmdp-range {
    @apply text-white shadow-none bg-transparent rounded-lg;
  }
  .custom-calendar .rmdp-day.rmdp-range span {
    @apply text-white shadow-none bg-danger rounded-md;
  }

  /* general setting  */

  .custom-calendar .rmdp-week-day {
    @apply text-neutral-50 font-semibold;
  }

  .custom-calendar .rmdp-day.rmdp-deactive {
    @apply text-neutral-30;
  }

  .rmdp-day.rmdp-selected span:not(.highlight) {
    @apply shadow-none;
  }

  .custom-calendar .rmdp-day span {
    @apply rounded-md;
  }

  .custom-calendar .b-deselect {
    @apply text-neutral-50 bg-white;
  }

  .custom-calendar .rmdp-action-button {
    @apply text-neutral-50;
  }

  .custom-calendar .rmdp-button:not(.rmdp-action-button) {
    @apply bg-transparent;
  }

  .custom-calendar .rmdp-button:not(.rmdp-action-button):hover {
    @apply bg-transparent;
  }

  .custom-calendar .rmdp-time-picker div input {
    width: 28px;
    color: inherit;
  }
}

input:checked ~ .dot {
  transform: translateX(100%);
  right: 2px;
}

input:checked ~ .backside {
  background-color: #1d4ed8 !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Custom Scrollbar */
/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #eaecf0;
  border-radius: 8px;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #eaecf0;
  border-radius: 8px;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #eaecf0;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* tinymce hard style  */
.tox-tinymce {
  border-radius: 8px !important;
  border: 1px solid #f5f7f9 !important;
}

.tox .tox-edit-area__iframe {
  background-color: #f5f7f9 !important;
}

.tox-statusbar {
  display: none !important;
}

.tox-toolbar__primary {
  background: none !important;
}

.tox .tox-editor-header {
  z-index: 0 !important;
}

@layer components {
  .custom-calendar .rmdp-wrapper {
    border: 1px solid #000000;
    box-shadow: 0 0 5px #000000;
  }

  .custom-calendar .rmdp-panel-body li {
    background-color: #000000;
    box-shadow: 0 0 2px #000000;
  }

  .custom-calendar .rmdp-week-day {
    @apply text-neutral-100 font-semibold;
  }

  .custom-calendar .rmdp-day.rmdp-deactive {
    @apply hover:bg-neutral-20 text-neutral-30 rounded;
  }

  .custom-calendar .rmdp-range {
    background-color: #000000;
    box-shadow: 0 0 3px #000000;
  }

  .custom-calendar .rmdp-arrow {
    border: solid #000000;
    border-width: 0 2px 2px 0;
  }

  .custom-calendar .rmdp-arrow-container:hover {
    background-color: #000000;
    box-shadow: 0 0 3px #000000;
  }

  .custom-calendar .rmdp-panel-body::-webkit-scrollbar-thumb {
    background: #000000 !important;
  }

  .custom-calendar .rmdp-day.rmdp-today span {
    @apply bg-primary-50 text-danger rounded;
  }

  .custom-calendar .rmdp-rtl .rmdp-panel {
    border-left: unset;
    border-right: 1px solid #000000;
  }

  .custom-calendar .rmdp-day.rmdp-selected span:not(.highlight) {
    @apply bg-blue-600 text-white rounded;
  }

  .custom-calendar .rmdp-day:not(.rmdp-day-hidden) span:hover {
    @apply hover:bg-neutral-20 hover:text-black rounded;
  }

  .custom-calendar .b-deselect {
    color: #000000;
    background-color: white;
  }

  .custom-calendar .rmdp-action-button {
    color: #000000;
  }

  .custom-calendar .rmdp-button:not(.rmdp-action-button) {
    background-color: #000000;
  }

  .custom-calendar .rmdp-button:not(.rmdp-action-button):hover {
    background-color: #000000;
  }
}

.splash-screen {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f2f3f8;
}

.splash-screen img {
  margin-bottom: 30px;
}

.tooltip-custom {
  padding: 2px 6px !important;
  font-size: 0.625rem !important;
  line-height: 16px !important;
  letter-spacing: 0.4px !important;
}

.tooltip-descName {
  background-color: white !important;
  color: #212529 !important;
  padding: 1rem !important;
  width: 270px !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 20px !important;
  letter-spacing: 0.25px !important;
  border: 1px solid #e9ecef;
  border-radius: 16px;
}

.tooltip-descName-arrow {
  border: 1px solid #dee2e6;
}

.faq-line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.rmdp-time-picker div input {
  width: 50px !important;
}

.text-gradient-success {
  color: #1b8c17;
  background-image: -webkit-linear-gradient(0deg, #1b8c17 0%, #c3f6c1 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-color: transparent;
}

.text-gradient-warning {
  color: #c98602;
  background-image: -webkit-linear-gradient(0deg, #c98602 0%, #f2e3c3 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-color: transparent;
}

.text-gradient-danger {
  color: #c8332f;
  background-image: -webkit-linear-gradient(0deg, #c8332f 0%, #e0a9a8 50%);
  background-clip: text;
  -webkit-background-clip: text;

  -webkit-color: transparent;
}

.text-gradient-info {
  color: #0f4f9e;
  background-image: -webkit-linear-gradient(0deg, #0f4f9e 0%, #bbcce0 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-color: transparent;
}

.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child,
.recharts-wrapper .recharts-cartesian-grid-vertical line:first-child,
.recharts-wrapper .recharts-cartesian-grid-vertical line:last-child {
  stroke-opacity: 0 !important;
}

/* gmaps */

.leaflet-control-geosearch .results {
  background: white !important;
  border: 1px solid #ccc !important;
  border-radius: 8px !important;
  padding: 8px !important;
  list-style: none !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
  max-height: 250px !important;
  overflow-y: auto !important;
  z-index: 9999 !important;
}

.leaflet-control-geosearch .results li {
  padding: 12px !important;
  cursor: pointer !important;
  font-size: 14px !important;
  color: #333 !important;
  background: white !important;
  border-bottom: 1px solid #ddd !important;
}

.leaflet-control-geosearch .results li:hover {
  background: #f0f0f0 !important;
}

.button-disabled,
.datepicker-disabled {
  opacity: 0.5;
  pointer-events: none;

  input {
    background-color: #f9fafb;
  }
}
